import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useFirebase } from '../context/FirebaseContext';
import PlayerInfo from './PlayerInfo';
import MatchHistory from './MatchHistory';
import ParticlesBg from '../ParticlesBg';

const PlayerLookup = () => {
  const [settings] = useState({
    backgroundColors: 'linear-gradient(135deg, #1A237E 0%, #FF8A65 100%)',
    density: 0,
    dotColor: '#5cbdaa',
    lineColor: '#5cbdaa',
    particleRadius: 0,
    lineWidth: 0,
    proximity: 0,
    minSpeed: 0,
    maxSpeed: 0,
    isGradient: true,
    createOnClick: 0,
    repulse: 0,
  });

  const [cossyId, setCossyId] = useState('');
  const [playerData, setPlayerData] = useState(null);
  const [matches, setMatches] = useState([]);
  const [error, setError] = useState('');
  const [tournamentName, setTournamentName] = useState('Loading...');
  const { tournamentId } = useParams();
  const { db } = useFirebase();
  const navigate = useNavigate();

  // Fetch tournament metadata and check existence
  useEffect(() => {
    const fetchTournamentMetadata = async () => {
      try {
        const metadataRef = doc(db, 'tournamentMetadata', tournamentId);
        const metadataDoc = await getDoc(metadataRef);
        
        if (metadataDoc.exists()) {
          const metadata = metadataDoc.data();
          setTournamentName(metadata.tournamentName);
        } else {
          // Tournament doesn't exist, redirect to home page
          navigate('/');
        }
      } catch (error) {
        console.error('Error fetching tournament metadata:', error, " Try refreshing the page.");
        // Redirect on error as well
        navigate('/');
      }
    };

    fetchTournamentMetadata();
  }, [db, tournamentId, navigate]);

  const fetchTournamentData = async () => {
    if (cossyId.length !== 10) {
      setError('Please enter a valid 10-digit COSSY ID.');
      return;
    }

    try {
      // Fetch player data
      const playerRef = collection(db, 'tournaments', tournamentId, 'participants');
      const playerQuery = query(playerRef, where('cossyId', '==', cossyId));
      const playerSnapshot = await getDocs(playerQuery);

      if (playerSnapshot.empty) {
        setError('Player not found.');
        setPlayerData(null);
        setMatches([]);
        return;
      }

      const player = playerSnapshot.docs[0].data();
      setPlayerData(player);

      // Fetch matches
      const matchesRef = collection(db, 'tournaments', tournamentId, 'matches');
      const matchesSnapshot = await getDocs(matchesRef);

      setMatches(matchesSnapshot);
      setError('');
    } catch (error) {
      console.error('Fetch error:', error);
      setError('An error occurred: ' + error.message + " Try refreshing the page.");
      setPlayerData(null);
      setMatches([]);
    }
  };

  return (
    <div className="container">
      <ParticlesBg {...settings} />
      <h1>Tournament Pairings </h1>
      <h3>Tournament : {tournamentName}</h3>
      <h6>ID: {tournamentId}</h6>
    
      <div className="search-container">
        <input
          type="text"
          value={cossyId}
          onChange={(e) => setCossyId(e.target.value)}
          placeholder="Enter your COSSY ID (10 digits)"
          maxLength={10}
        />
        <button onClick={fetchTournamentData}>Search</button>
      </div>
      
      {error && <div className="error">{error}</div>}
      
      {playerData && (
        <div className="results">
          <PlayerInfo playerData={playerData}/>
          <div className={cossyId}/>
          <MatchHistory matches={matches} playerCossyId={cossyId} tournamentId={tournamentId} />
          </div>
      )}
    </div>
  );
};

export default PlayerLookup;