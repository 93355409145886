import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import TournamentLookup from './components/TournamentLookup';
import PlayerLookup from './components/PlayerLookup';
import { FirebaseProvider } from './context/FirebaseContext';
import { Helmet } from 'react-helmet';
import './App.css';

const App = () => {
  return (
    <FirebaseProvider>
      <Helmet>
        <meta name="viewport" 
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<TournamentLookup />} />
          <Route path="/:tournamentId" element={<PlayerLookup />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </FirebaseProvider>
  );
};

export default App;
