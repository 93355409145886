import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { useFirebase } from '../context/FirebaseContext';
import ParticlesBg from '../ParticlesBg';

const TournamentLookup = () => {
  const [settings] = useState({
    backgroundColors: 'linear-gradient(135deg, #0D3F13 0%, #C4A01C 100%)',
    density: 0,
    dotColor: '#5cbdaa',
    lineColor: '#5cbdaa',
    particleRadius: 0,
    lineWidth: 0,
    proximity: 0,
    minSpeed: 0,
    maxSpeed: 0,
    isGradient: true,
    createOnClick: 0,
    repulse: 0,
  });

  const [tournamentId, setTournamentId] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [tournamentInfo, setTournamentInfo] = useState(null);
  const navigate = useNavigate();
  const { db } = useFirebase();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    setTournamentInfo(null);
  
    if (!tournamentId.trim()) {
      setError('Please enter a tournament ID.');
      setLoading(false);
      return;
    }
  
    try {
      // First, check the tournamentMetadata collection
      const metadataRef = doc(db, 'tournamentMetadata', tournamentId.trim());
      const metadataDoc = await getDoc(metadataRef);
  
      if (metadataDoc.exists()) {
        const metadata = metadataDoc.data();
        setTournamentInfo({
          name: metadata.tournamentName,
          lastUpdate: new Date(metadata.lastUpdate).toLocaleString(),
          creationDate: new Date(metadata.creationDate).toLocaleString()
        });

        // Optional: You can add a slight delay here to show the tournament info before navigating
        setTimeout(() => {
          navigate(`/${tournamentId.trim()}`);
        }, 1500);
      } else {
        setError('No tournament found with the provided ID. Try again or Refresh.');
      }
  
    } catch (error) {
      console.error('Error fetching tournament:', error);
      setError('An error occurred while searching for the tournament. Try Refeshing the page.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <ParticlesBg {...settings} />
      <h1>Tournament Lookup</h1>
      <form onSubmit={handleSubmit} className="lookup-form">
        <input
          type="text"
          value={tournamentId}
          onChange={(e) => setTournamentId(e.target.value)}
          placeholder="Enter Tournament ID"
          required
          className="tournament-input"
          disabled={loading}
        />
        <button type="submit" disabled={loading} className="search-button">
          {loading ? 'Searching...' : 'Search Tournament'}
        </button>
      </form>

      {error && <div className="error-message">{error}</div>}
      
      {tournamentInfo && (
        <div className="tournament-info">
          <h2>{tournamentInfo.name}</h2>
          <div className="redirect-message">
            Redirecting to tournament page...
          </div>
        </div>
      )}
    </div>
  );
};

export default TournamentLookup;